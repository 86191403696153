.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.marker-btn img {
  width: 30px;
  height: 30px;
}
